@mixin header-icon($icon) {
  background: transparent url("../img/" + $icon) no-repeat scroll left center;
  padding-left: 1.5em;
}

@mixin strong-icon($icon) {
  strong {
    padding-left: 1.5em;
    background: transparent url("../img/" + $icon) no-repeat scroll;
  }
}
