@mixin delimited-list($separator: ", ") {
  li {
    list-style: none;
    display: inline;
    &:after {
      content: $separator;
    }
    &:last-child {
      &:after {
        content: "";
      }
    }
    &.last {
      &:after {
        content: ""; }
    }
  }
}
