& {

  @mixin normal-text      { font-family: $font-family; }
  @mixin fixed-width-text { font: 1em $font-family-fixed; line-height: 1.5; }
  @mixin header-text      { font-weight: normal; }
  @mixin cite-text        { padding: 1em;}

  line-height: 1.5;
  @include normal-text;
  font-size: 100% * $font-size / 16px;
  color-profile: sRGB;
  rendering-intent: auto;

  h1, h2, h3,
  h4, h5, h6      { @include header-text; }
  h1              { font-size: 3em;   line-height: 1;    margin-bottom: 0.50em; }
  h2              { font-size: 2em;                      margin-bottom: 0.75em; }
  h3              { font-size: 1.5em; line-height: 1;    margin-bottom: 1.00em; }
  h4              { font-size: 1.2em; line-height: 1.25; margin-bottom: 1.25em; }
  h5              { font-size: 1em;   font-weight: bold; margin-bottom: 1.50em; }
  h6              { font-size: 1em;   font-weight: bold;                        }
  p               { margin: 0 0 1.5em; }
  a               { text-decoration: none;
    &:hover       { text-decoration: underline }  }
  blockquote      { margin: 1.5em;
                    font-style: italic;
                    @include cite-text;
    p             { margin: 0; } }
  strong, dfn     { font-weight: bold; }
  em, dfn         { font-style: italic; }
  sup, sub        { line-height: 0; }
  address         { margin: 0 0 1.5em; font-style: italic; }
  pre, code, tt   { @include fixed-width-text; }
  pre             { margin: 1.5em 0; white-space: pre-wrap; line-height: 110%;
                    @include cite-text; }
  li ul, li ol    { margin: 0; }
  ul, ol          { margin: 0 1.5em 1.5em 0; padding-left: 1.5em; }
  ul              { list-style-type: disc; }
  ol              { list-style-type: decimal; }
  li              { list-style-image: url("../img/bullet.png"); }
  dl              { margin: 0 0 1.5em 0;
    dt            { font-weight: bold; } }
  dd              { margin-left: 1.5em; }
  table           { margin-bottom: 1.4em; width: 100%; }
  th              { font-weight: bold; }
  th, td, caption { padding: 4px 10px 4px 5px; }
  tfoot           { font-style: italic; }
  img.help        { cursor: help; }
  li p            { margin: 0; }
}
