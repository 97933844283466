form {
  label {
    display: block;
    font-weight: bold; }
  fieldset {
    padding: 1.4em;
    margin: 0 0 1.5em 0; }
  legend {
    font-weight: bold;
    font-size: 1.2em; }
  input {
    &[type=email],
    &[type=url],
    &[type=text],
    &[type=password] {
      margin: 0.5em 0;
      width: 50%;
      padding: 0.5em; }
  }
  textarea {
    margin: 0.5em 0;
    padding: 0.5em;
    width: 70%;
    height: 300px; }
  select {
    margin: 0.5em 0;
  }
  ul.errorlist {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      display: inline;
    }
  }
}
