@mixin link-colors($normal, $hover: false, $active: false, $visited: false, $focus: false) {
  color: $normal;
  @if $visited {
    &:visited {
      color: $visited; } }
  @if $focus {
    &:focus {
      color: $focus; } }
  @if $hover {
    &:hover {
      color: $hover; } }
  @if $active {
    &:active {
      color: $active; } } }
