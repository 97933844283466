@mixin breakpoint($breakpoint) {
  @if $breakpoint == "large" {
    @media (min-width: 1024px) { @content; }
  }
  @if $breakpoint == "medium" {
    @media (min-width: 768px) { @content; }
  }
  @if $breakpoint == "small" {
    @media (min-width: 480px) { @content; }
  }
}