.breadcrumbs {
  display: none;
}

@include breakpoint("small") {
  .breadcrumbs {
    display: block;
    @include delimited-list("/");
    margin: 0;
    font-size: 1.2em;
    background: transparent url("../img/breadcrumb.png") no-repeat scroll left center;
  }
}