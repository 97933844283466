#header {
  margin-bottom: 1em;

  h1 {
    margin-top: 0.5em;
    margin-bottom: 0;
    font-weight: bold;
    &:first-letter {
      font-size: 130%;
    }
  }

  blockquote {
    border: none;
    background: none;
    margin:  0 0 1.5em 0;
    padding: 0 1.5em 0 0;
  }

  form {
    float: right;
    clear: both;
    display: none;
    input[type=text] {
      width: 85%;
      background-image: url("../img/search.png");
      background-repeat: no-repeat;
      background-position: 5px center;
      padding-left: 25px;
      border-radius: 5px;
    }
    input[type=submit] {
      display: none;
    }
  }

  .top-navigation {
    margin: 0;
    float: right;
    display: none;
    @include delimited-list("|");
    .sitemap {
      @include header-icon("sitemap.png");
    }
    .feeds {
      @include header-icon("rss.png");
    }
  }

  @include breakpoint("small") {
    form, .top-navigation {
      display: block;
    }
  }
  @include breakpoint("medium") {
    h1 {
      font-size: 4em;
    }
  }
  @include breakpoint("large") {
    h1 {
      background: transparent url("../img/logo.png") no-repeat scroll left center;
      padding-left: 1.5em;
    }
    blockquote {
      margin-left: 6em;
    }
  }
}
