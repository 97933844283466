ul.paginator {
  margin: 0;
  padding: 1.5em 0;
  font-size: 1.2em;
  li {
    list-style: none;
    display: inline-block;
    margin-right: 0.3em;
    &.current {
      font-weight: bold;
    }
    &.current, &.index, &.ellipsis, * {
      padding: 0 0.5em;
    }
    &.index, &.previous, &.next, &.ellipsis {
      display: none;
    }
    @include breakpoint("small") {
      &.previous, &.next, &.ellipsis {
        display: inline-block;
      }
    }
    @include breakpoint("large") {
      &.index {
        display: inline-block;
      }
    }
  }
}
