// Layout
.slider-container {
  height: auto;
  max-width: 800px;
  position: relative;
  margin-bottom: 1em;
  clear: both;
  ul, ol, li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

.slider {
  width: 100%;
  position: relative;
  background: url("../img/loader.gif") no-repeat center center;
}

.slides {
  &:before, &:after {
   content: " ";
   height: 0;
   display: block;
   overflow: hidden;
  }
  &:after {
    clear: both;
  }
}

.slide {
  display: none;
  position: relative;
  img {
    width: 100%;
    height: 250px;
    display: block;
  }
}

.slide-content {
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  padding: 1em 2em;
  h2, p {
    text-shadow: 1px 1px 0 #000000;
    background-color: rgba(0, 0, 0, 0.7);
  }
  h2 {
    padding: 0.25em;
    font-weight: bold;
    display: inline-block;
  }
  p {
    color: #fff;
    padding: 1em;
    display: none;
  }
}

// Navigation controlers
ol.flex-control-nav {
  width: 100%;
  z-index: 999;
  text-align: center;
  margin-top: 1em;
  li {
    display: inline;
    margin: 0 0.5em;
    a {
      height: 0;
      width: 8px;
      overflow: hidden;
      padding-top: 8px;
      display: inline-block;
      border-radius: 10px;
      -moz-border-radius: 10px;
    }
  }
}

.flex-direction-nav {
  a {
    height: 0;
    width: 14px;
    padding-top: 22px;
    display: block;
    bottom: 0;
    position: absolute;
    overflow: hidden;
    opacity: 0.7;
    &:hover { opacity: 1; }
  }
  .prev { @include nav-direction-button("prev.png", left); }
  .next { @include nav-direction-button("next.png", right); }
}

// Responsive
@media (min-width: 900px) {
  .slider-container {
    padding-left: 8.43373%;
    padding-right: 8.43373%;
  }
  .slide-content {
    h2 { font-size: 3em; }
    p { font-size: 1.2em; display: inline-block; }
  }
  .slide img {
    height: 360px;
  }
  .flex-direction-nav {
    .prev { @include hd-nav-direction-button(left); }
    .next { @include hd-nav-direction-button(right); }
  }
}

@media (max-width: 400px) {
  .slider-container {
    display: none;
  }
}
