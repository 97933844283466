// Page layout can be done using mixins applied to your semantic classes and IDs
// For instance this layout defines a two column layout on pages with
// a body id of "zinnia".
//
// The markup would look like:
//    <div class="container">
//      <div id="header"></div>
//      <div id="content"></div>
//      <div id="sidebar"></div>
//      <div id="footer"></div>
//    </div>
//
// and the layout would look like:
//    +------------------------+
//    |        #header         |
//    +---------------+--------+
//    |               |        |
//    |   #content    |#sidebar|
//    |               |        |
//    +------------------------+
//    |        #footer         |
//    +------------------------+

& {
  .container {
    *zoom: 1;
    max-width: $single-column-width;
    padding-left: $gutter-width;
    padding-right: $gutter-width;
    margin-left: auto;
    margin-right: auto;
    background-origin: content-box;
    background-clip: content-box;
    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }

  #header, #footer {
    clear: both;
  }
}


@include breakpoint("large") {
  $total-columns     : $multi-columns-width + $gutter-width;
  $one-em-percent    : (100.0 / $total-columns) * 1%;
  $one-third-percent : ($multi-columns-width / 3) * $one-em-percent;
  $two-third-percent : $one-third-percent * 2;
  $gutter-percent    : $gutter-width * $one-em-percent;

  & .container {
    max-width: $multi-columns-width;
  }

  & #content, &.right-sidebar #content {
    width: $two-third-percent;
    float: left;
    margin-right: $gutter-percent;
  }
  & #sidebar, &.right-sidebar #sidebar {
    width: $one-third-percent;
    float: right;
    margin-right: 0;
  }
  &.left-sidebar #content {
    width: $two-third-percent;
    float: right;
    margin-right: 0;
  }
  &.left-sidebar #sidebar {
    width: $one-third-percent;
    float: left;
    margin-right: $gutter-percent;
  }
  &.no-sidebar #content {
    width: 100%;
    float: left;
    margin-right: $gutter-percent;
  }
  &.no-sidebar #sidebar {
    display: none;
  }
}
