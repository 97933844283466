// Load configurables variables.
@import "config/base";
@import "config/solarized";
// Import the mixins
@import "mixins/icons";
@import "mixins/tag-cloud";
@import "mixins/alert-box";
@import "mixins/breakpoint";
@import "mixins/link-colors";
@import "mixins/delimited-list";
@import "mixins/slider-buttons";
// Reset the elements.
@import "partials/reset.scss";
// Combine the partials into a single screen stylesheet.
// under the body.zinnia class to avoid interferences
.zinnia {
  @import "partials/page";
  @import "partials/forms";
  @import "partials/layouts";
  @import "partials/header";
  @import "partials/slider";
  @import "partials/breadcrumbs";
  @import "partials/entry";
  @import "partials/linkbacks";
  @import "partials/feeds";
  @import "partials/archives";
  @import "partials/paginator";
  @import "partials/footer";
  @import "partials/sidebar";
  @import "partials/themes";
  @import "partials/interactions";
}
